@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
}

body {
  @apply bg-neutral min-h-screen min-w-full;
}

.headline1 {
  @apply text-4xl font-poppins font-bold;
}

.headline2 {
  @apply text-3xl font-poppins font-bold;
}

.headline3 {
  @apply text-2xl font-poppins font-bold;
}

.headline4 {
  @apply text-xl font-poppins font-bold;
}

.headline5 {
  @apply text-lg font-montserrat font-extrabold tracking-[0.5px];
}

.body1 {
  @apply text-base font-montserrat tracking-[0.5px];
}

.body2 {
  @apply text-[14px] font-montserrat leading-[121%] tracking-[0.5px];
}

.ariane {
  @apply text-sm font-montserrat tracking-[0.5px];
}

tui-root {
  --tui-primary: theme('colors.primary');
  --tui-primary-hover: theme('colors.primary-dark');
  --tui-link: theme('colors.primary');
  --tui-link-hover: theme('colors.primary-dark');
  --tui-primary-text: theme('colors.white');
  --tui-secondary-hover: theme('colors.primary-light');
  --tui-text-01: theme('colors.primary-dark');
  --tui-base-01: theme('colors.neutral');

  .input {
    [tuiWrapper][data-appearance='textfield'] {
      @apply shadow-none border border-neutral-dark rounded-lg mt-1 focus:rounded-lg;
    }
  }

  .toggle {
    --tui-secondary: theme('colors.neutral-dark');
    --tui-secondary-hover: theme('colors.neutral-dark');
  }

  .phone {
    @apply mt-1;

    [tuiWrapper][data-appearance='textfield'] {
      @apply border border-neutral-dark first:mr-0 first:rounded-tl-lg first:rounded-bl-lg;
    }

    .t-input-phone {
      [tuiWrapper][data-appearance='textfield'] {
        @apply border border-neutral-dark rounded-l-none rounded-r-lg;
      }
    }
  }

  .label {
    @apply body1 font-bold text-primary-dark;
  }

  ol {
    @apply list-decimal px-4;
  }

  .test-map {
    .map-container {
      width: 100% !important;
    }
  }
}
